import { useGooglePay } from '@web-solutions/react-billing';

import { InertialApplePay, ModeApplePay, ModePayPal, ModeGooglePay, ModeMercadoPago, } from 'core/constants/billing';
import { useApplePayCanMakePayments } from 'core/hooks/use-apple-pay-can-make-payments';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { ProductDetails } from 'core/store/billing/selectors';

export const useShowPayPal = (product?: ProductDetails) => {
  const { modePayPal } = useRemoteConfig();

  const applePayCanMakePaymentsWithActiveCard = useApplePayCanMakePayments(true);

  return (
    (
      !product?.isOneTimePurchase
      &&
      (
        modePayPal === ModePayPal.ENABLED ||
        modePayPal === ModePayPal.SOLIDGATE ||
        (modePayPal === ModePayPal.DISABLED_IF_APPLE_PAY_WITH_CARD && applePayCanMakePaymentsWithActiveCard === false)
      )
    )
    ||
    (
      !!product?.isOneTimePurchase
      &&
      modePayPal === ModePayPal.SOLIDGATE
    )
  );
};

export const useShowApplePay = () => {
  const { modeApplePay } = useRemoteConfig();

  const available = useApplePayCanMakePayments(modeApplePay === ModeApplePay.ENABLED_IF_WITH_CARD);

  return modeApplePay !== ModeApplePay.DISABLED && available;
}

export const useShowGooglePay = () => {
  const { modeGooglePay } = useRemoteConfig();

  const available = useGooglePay(modeGooglePay === ModeGooglePay.ENABLED_IF_PAYMENT_METHOD_PRESENT);

  return modeGooglePay !== ModeGooglePay.DISABLED && available;
}

export const useShowInertialApplePay = () => {
  const { inertialApplePay } = useRemoteConfig();

  const applePayCanMakePaymentsWithActiveCard = useApplePayCanMakePayments(true);

  return (inertialApplePay === InertialApplePay.ENABLED || inertialApplePay === InertialApplePay.ENABLED_WITH_DEFAULT) || (inertialApplePay === InertialApplePay.ENABLED_IF_WITH_CARD && applePayCanMakePaymentsWithActiveCard);
}

export const useShowMercadoPago = () => {
  const { modeMercadoPago } = useRemoteConfig();

  return modeMercadoPago === ModeMercadoPago.ENABLED;
};
