import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import Analytics from '@web-solutions/module-analytics';

import { ThemeContextProvider } from 'core/hooks/use-theme-context';
import { Theme } from 'core/constants/theme';
import { CookieClear } from 'core/containers/cookie-clear';

import { initLocalization } from 'src/localization';

import { getMagnusToken } from 'src/store/app/utils';

import packageInfo from '../package.json';

//@ts-ignore
import configureStore from './store';

import 'react-toastify/dist/ReactToastify.min.css';
import 'src/styles/global.scss';
import 'src/styles/index.scss';
import 'src/styles/variables.css';

const App = (AppRouter: React.FC) => {
  const pType = window.location.pathname.split('/')[1];
  const { store, persistor } = configureStore();

  Analytics.init(getMagnusToken(), packageInfo.version);

  if (pType !== 'terminate') {
    (async () => {
      const { load } = await import('./store/app/actions');
      store.dispatch(load());
    })();
  }

  initLocalization();

  return (
    <StrictMode>
      <Provider store={store}>
        <CookieClear>
          <ThemeContextProvider value={{ theme: Theme.light }}>
            <PersistGate loading={null} persistor={persistor}>
              <AppRouter />
              <ToastContainer position="bottom-center" autoClose={5000} closeOnClick />
            </PersistGate>
          </ThemeContextProvider>
        </CookieClear>
      </Provider>
    </StrictMode>
  );
};

export const render = (AppRouter: React.FC) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(App(AppRouter));
};
