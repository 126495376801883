import { parseUrlParams } from '@web-solutions/core/utils/url-sync';

import { render } from './render';

parseUrlParams();

let paths = window.location.pathname.split('/');

let promiseApp;

if (paths[1] === 'terminate') {
  promiseApp = import('@web-solutions/manage-subscription').then(r => r.Terminate);
} else if (paths[1] === 'quiz-book') {
  promiseApp = import('./screens/quiz/book').then(r => r.default);
} else if (paths[1] === 'summary-book') {
  promiseApp = import('./screens/subscription/book').then(r => r.default);
} else {
  promiseApp = import('./screens/quiz').then(r => r.default);
}

promiseApp.then(render);
